import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import * as Survey from 'survey-react';
import { StringParam, useQueryParam } from 'use-query-params';

import { Layout, SEO } from '../../components';
import { SURVEY_FOR_PARENTS } from '../../data/surveyForParents';
import { wait } from '../../lib';
import getFirebase from '../../lib/firebase';
import './style.scss';

const surveyModel = new Survey.Model(SURVEY_FOR_PARENTS);
if (typeof window !== 'undefined') {
  Survey.StylesManager.applyTheme('default');
}

const SurveyForParentsPage = () => {
  const [surveyState, setSurveyState] = useState();
  const [clickId] = useQueryParam('clickid', StringParam);

  async function handleComplete(result) {
    setSurveyState('completing');
    window.scrollTo(0, 0);

    await Promise.all([
      (async () => {
        try {
          const firebase = getFirebase();
          if (!firebase) {
            console.log('[firebase] firebase is not defined; skipping...');
            return;
          }
          console.log('[firebase] authenticating user...');
          await firebase.auth().signInAnonymously();
          console.log('[firebase] saving result...');
          await firebase
            .firestore()
            .doc(
              `survey-for-parents-results/${firebase.auth().currentUser.uid}`
            )
            .set(result?.data);
          console.log('[firebase] done.');
        } catch (e) {
          console.error('[firebase] error saving result', e);
        }
      })(),
      fetch(
        `https://ad.propellerads.com/conversion.php?aid=861434&pid=&tid=79336&visitor_id=${clickId}&payout=0.01`
      ).catch(() => undefined),
      wait(3000),
    ]);

    setSurveyState('dataSent');
    await wait(2000);
    setSurveyState('loadReward');
    await wait(3000);
    setSurveyState('reward');
  }

  const [loadingDots] = useSpring(() => ({
    from: { num: 0 },

    to: async (next) => {
      while (1) {
        await next({ num: 0 });
        await next({ num: 3 });
      }
    },
  }));

  return (
    <Layout bodyClass="SurveyForParentsPage min-h-screen">
      <SEO
        title="Internet Safety Survey for Parents"
        url="/survey-for-parents/"
      />
      <div className="container mx-auto p-4">
        <Survey.Survey model={surveyModel} onComplete={handleComplete} />
        {surveyState && (
          <div
            className="bg-primary-100 rounded flex flex-col justify-center items-center h-64 text-2xl"
            onClick={
              surveyState === 'reward'
                ? () =>
                    navigate(`/survey-for-parents/reward/?clickid=${clickId}`)
                : undefined
            }
          >
            {surveyState === 'completing' ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 mb-4 animate-spin"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  />
                </svg>
                <div>
                  Analyzing survey data
                  <animated.span>
                    {loadingDots.num.interpolate((v) => {
                      return new Array(Math.round(v)).fill('.').join('');
                    })}
                  </animated.span>
                </div>
              </>
            ) : surveyState === 'dataSent' ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 mb-4 animate-bounce"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <div>Ready!</div>
              </>
            ) : surveyState === 'loadReward' ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 mb-4 animate-spin"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  />
                </svg>
                <div>
                  Preparing your reward
                  <animated.span>
                    {loadingDots.num.interpolate((v) => {
                      return new Array(Math.round(v)).fill('.').join('');
                    })}
                  </animated.span>
                </div>
              </>
            ) : surveyState === 'reward' ? (
              <>
                <div>Your reward is ready!</div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-24 w-24 mb-4 mt-8 animate-bounce text-secondary"
                  viewBox="0 0 100 100"
                  fill="currentColor"
                >
                  <path d="M75.18976,7.43937C70.95029,3.46554,62.07288,4.4651,56.1927,9.57864A30.99925,30.99925,0,0,0,50,17.13176a30.99925,30.99925,0,0,0-6.1927-7.55312c-5.88018-5.114-14.76052-6.1131-18.99706-2.13927a5.72849,5.72849,0,0,0-1.796,5.66582c.81742,3.46551,4.592,6.516,10.09719,8.1596a65.36728,65.36728,0,0,0,16.75574,2.27257c.06812,0,.10718-.0003.13282-.00042.02564.00012.0647.00042.13282.00042a65.38106,65.38106,0,0,0,16.75574-2.27257c5.50516-1.64364,9.27977-4.69409,10.09719-8.1596A5.72849,5.72849,0,0,0,75.18976,7.43937ZM34.256,17.43158c-4.54125-1.356-6.96324-3.611-7.348-5.24441a1.76271,1.76271,0,0,1,.63675-1.8287c2.463-2.30774,8.95554-1.83261,13.63742,2.23888a27.24144,27.24144,0,0,1,5.52372,6.83189A57.38719,57.38719,0,0,1,34.256,17.43158Zm38.836-5.24441c-.38479,1.63339-2.80678,3.88839-7.348,5.24441a57.49683,57.49683,0,0,1-12.45572,1.99669,27.20593,27.20593,0,0,1,5.52958-6.83092,14.5434,14.5434,0,0,1,9.2329-3.62079,6.453,6.453,0,0,1,4.40452,1.38191A1.76271,1.76271,0,0,1,73.092,12.18717ZM45.9998,95H22.81129a3.25288,3.25288,0,0,1-3.25285-3.25285V48.54768H45.9998Zm31.18891,0H54.0002V48.54768H80.44156V91.74715A3.25288,3.25288,0,0,1,77.18871,95ZM19.55112,27.86656H45.9998V44.54747H19.55112a3.25287,3.25287,0,0,1-3.25285-3.25285V31.11942A3.25288,3.25288,0,0,1,19.55112,27.86656ZM80.44888,44.54747H54.0002V27.86656H80.44888a3.25288,3.25288,0,0,1,3.25285,3.25286v10.1752A3.25287,3.25287,0,0,1,80.44888,44.54747Z" />
                </svg>

                <div>Tap to open it!</div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SurveyForParentsPage;
