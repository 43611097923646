export const SURVEY_FOR_PARENTS = {
  title: 'Internet Safety Survey for Parents',
  pages: [
    {
      name: 'intro',
      elements: [
        {
          type: 'checkbox',
          name: 'question1',
          title: 'How old are your kids? (select all that apply)',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'My kids are preschoolers',
            },
            {
              value: 'item2',
              text: 'My kids are in school (or highschool)',
            },
            {
              value: 'item3',
              text: 'My kids are adults already',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question2',
          title:
            'Do your kids have their own internet access device (laptop, phone, PC, tablet, etc.)?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, they use a family device',
            },
          ],
          hasOther: true,
          otherPlaceHolder:
            'Please tell us the reasons your kids are not allowed to use the internet',
          otherText: 'No, they are not allowed on the internet',
        },
        {
          type: 'radiogroup',
          name: 'question4',
          title: 'Do you feel your kids are safe while using the internet?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes, they are safe while using the internet',
            },
            {
              value: 'item2',
              text: 'Not quite, they are exposed to a reduced level of danger',
            },
            {
              value: 'item3',
              text: 'No, they are exposed to a high level of danger',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question5',
          title:
            'Do your kids have social media accounts (Facebook, Twitter, Instagram, TikTok, etc.)?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No',
            },
            {
              value: 'item3',
              text: 'I don’t know',
            },
          ],
        },
      ],
      title: 'Intro',
      description: 'Questions regarding children internet presence',
    },
    {
      name: 'education',
      elements: [
        {
          type: 'checkbox',
          name: 'question6',
          title: 'Who is teaching your kids about internet safety?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'I teach them',
            },
            {
              value: 'item2',
              text: 'Another adult family member is teaching them',
            },
            {
              value: 'item3',
              text: 'They learn it at school',
            },
            {
              value: 'item4',
              text: 'Nobody',
            },
          ],
        },
        {
          type: 'checkbox',
          name: 'question7',
          visibleIf: "{question6} = ['item4']",
          title:
            'Please choose the reasons you don’t teach your kids about internet safety.',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'I don’t have time',
            },
            {
              value: 'item2',
              text: 'I don’t know how',
            },
            {
              value: 'item3',
              text: 'I didn’t know I should',
            },
          ],
          hasOther: true,
        },
        {
          type: 'checkbox',
          name: 'question8',
          title:
            'How do you check if your kids are in danger while using the internet?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'I use parental control software',
            },
            {
              value: 'item2',
              text: 'I supervise my kids when they use the internet',
            },
            {
              value: 'item3',
              text:
                'I don’t check because I trust my kids to make the best choices',
            },
          ],
          hasOther: true,
        },
        {
          type: 'radiogroup',
          name: 'question9',
          title:
            'Have your kids ever been hacked (someone accessed their accounts without their permission)?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No',
            },
            {
              value: 'item3',
              text: 'I don’t know',
            },
          ],
        },
      ],
      title: 'Internet Safety Education',
    },
    {
      name: 'account',
      elements: [
        {
          type: 'radiogroup',
          name: 'question10',
          title:
            'Have you told your kids to delete the accounts they no longer need?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know I should',
            },
            {
              value: 'item3',
              text: 'No, because that’s not necessary',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question11',
          title:
            'Do you assist your kids when they create accounts on the internet?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I don’t know how',
            },
            {
              value: 'item3',
              text: 'No, because I didn’t know I should',
            },
            {
              value: 'item4',
              text: 'No, because I trust them to make the best choices',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question12',
          title:
            'Have you told your kids not to give unnecessary personal information online?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know they shouldn’t',
            },
            {
              value: 'item3',
              text: 'No, because that’s not dangerous',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question13',
          title:
            'Have you told your kids to avoid logging into their personal accounts on public computers (like a school computer)?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know they shouldn’t',
            },
            {
              value: 'item3',
              text: 'No, because that’s not dangerous',
            },
          ],
        },
      ],
      title: 'Online accounts',
      description: 'Questions about online accounts owned by children',
    },
    {
      name: 'password',
      elements: [
        {
          type: 'radiogroup',
          name: 'question14',
          title: 'Are your kids using any kind of password manager?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'No, because I didn’t know they should',
            },
            {
              value: 'item2',
              text: 'No, because they don’t need one',
            },
            {
              value: 'item3',
              text: 'No, because it’s too expensive',
            },
          ],
          hasOther: true,
          otherPlaceHolder: 'Which password manager?',
          otherText: 'Yes ',
        },
        {
          type: 'radiogroup',
          name: 'question15',
          title:
            'Have you told your kids to use a different password for each website?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know they should',
            },
            {
              value: 'item3',
              text: 'No, because that’s not necessary',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question16',
          title:
            'Have you told your kids that it is dangerous to write passwords in a computer or phone document?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know that was dangerous',
            },
            {
              value: 'item3',
              text: 'No, because that’s not necessary',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question17',
          title:
            'Have you told your kids to change their passwords at least once per year?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know they should',
            },
            {
              value: 'item3',
              text: 'No, because that’s not necessary',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'question18',
          title:
            'Have you told your kids NOT to share passwords with their friends?',
          isRequired: true,
          choices: [
            {
              value: 'item1',
              text: 'Yes',
            },
            {
              value: 'item2',
              text: 'No, because I didn’t know they shouldn’t',
            },
            {
              value: 'item3',
              text: 'No, because that’s ok',
            },
          ],
        },
      ],
      title: 'Passwords',
      description: "Questions about children's password hygene",
    },
  ],
  showTitle: false,
  showProgressBar: 'top',
};
